<template>
  <div class="enterprise">
    <div>
      <certification-content>
        <div slot="menu">
          账号认证<span class="main-text F16">（企业）</span>
        </div>
        <div slot="title">
          <span class="F16">法人信息</span>
          <span class="F14 co_f00">（可先上传营业执照，上传成功后会自动读取营业执照内的信息）</span>
        </div>
        <div slot="information">
          <certification-form></certification-form>
        </div>
      </certification-content>
    </div>
  </div>
</template>
<script>
import CertificationContent from "@/components/certificationContent";
import CertificationForm from "@/components/certificationForm";
export default {
  components: {
    CertificationContent,
    CertificationForm,
  },
  name: "enterprise",
};
</script>
<style scoped lang="scss">
</style>