<template>
  <div class="certificationform">
    <div class="form">
      <div v-if="userInfo.authentication != 'Y' || isRecertification" class="textC Mtp20">
        <div class="co_999 F16">请使用微信扫一扫进行身份认证</div>
        <vue-qr :text="imageUrl" :size="200"></vue-qr>
      </div>
      <el-form
        v-else
        :model="userInfo"
        :rules="certificationRules"
        ref="certificationForm"
        label-width="100px"
      >
        <el-row :gutter="100">
          <el-col :span="12">
            <el-form-item label="姓名：" prop="mobile" label-width="110px">
              {{ userInfo.name }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证：" label-width="110px">
              {{ userInfo.idCard }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="100">
          <el-col :span="12">
            <el-form-item label="身份证正面：" label-width="110px">
              <el-image
                :src="userInfo.idCardFront"
                style="width: 200px"
                :preview-src-list="[ userInfo.idCardFront ]"
              ></el-image>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证反面：" label-width="110px">
              <el-image
                :src="userInfo.idCardBack"
                style="width: 200px"
                :preview-src-list="[ userInfo.idCardBack ]"
              ></el-image>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form>
        <el-form-item>
          <div class="submitBtn Ptp30">
            <el-button type="primary" @click="refresh()" v-if="userInfo.authentication != 'Y' || isRecertification"
              >刷新认证状态</el-button
            >
            <el-button type="success" plain v-else>认证成功</el-button>
            <el-button
                v-if="!isRecertification"
                style="font-weight: bold; width: 150px"
                type="primary"
                @click="recertification()"
            >重新认证</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { updateUser, getVerifyResult } from "@/api/user";
import vueQr from "vue-qr";
import config from "@/config/website"
export default {
  name: "CertificationForm",
  components: { vueQr },
  data() {
    return {
      isRecertification: false,
      certificationForm: {
        mobile: "",
        companyAddress: "",
        verify: "",
        bankAccount: "",
        email: "",
      },
      certificationRules: {
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
        ],
        companyAddress: [
          { required: true, message: "请输入地址", trigger: "blur" },
        ],
        verify: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        bankAccount: [
          { required: true, message: "请输入银行卡号", trigger: "blur" },
        ],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
      },
      /* 获取到的验证码 */
      validateCode: null,
      imageUrl: "",
      codeTimer: Function,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.imageUrl =
      config.baseUrl + "/faceid/identity/verify?userId=" +
      this.userInfo.id;
    // var that = this;
    // this.codeTimer = setInterval(() => {
    //   this.getVerifyResult(this.userInfo.id).then((result) => {
    //     if (result.success) {
    //       this.$message.success(result.msg);
    //       window.clearInterval(this.codeTimer);
    //       // 更新用户信息
    //       updateUser(this.userInfo).then((res) => {
    //         if (res.success) {
    //           this.$message.success(res.msg);
    //           // 实时获取用户信息并且更新缓存
    //           this.$store.dispatch("GetLoginUserInfoByToken");
    //         } else {
    //           this.$message.error(res.msg);
    //         }
    //       });
    //     } else {
    //       this.$message.error(result.msg);
    //     }
    //   });
    // }, 5000);
  },
  destroyed() {
    // window.clearInterval(this.codeTimer); // 页面销毁前清除定时器
  },
  methods: {
    // 获取手机验证码
    // getCode() {
    //   getMobileCode(this.userInfo.mobile).then((res) => {
    //     if (res.success) {
    //       // console.log(res.data);
    //       this.validateCode = res.data;
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   });
    // },
    // 确认提交
    submitPerson(certification) {
      this.$refs[certification].validate((valid) => {
        if (valid) {
          this.getVerifyResult(this.userInfo.id).then((result) => {
            if (result.success) {
              this.$message.success(result.msg);
              // 更新用户信息
              updateUser(this.userInfo).then((res) => {
                if (res.success) {
                  this.$message.success(res.msg);
                  // 实时获取用户信息并且更新缓存
                  this.$store.dispatch("GetLoginUserInfoByToken");
                } else {
                  this.$message.error(res.msg);
                }
              });
            } else {
              this.$message.error(result.msg);
            }
          });
        }
      });
    },

    // 获取认证结果
    getVerifyResult(userId) {
      return new Promise((resolve, reject) => {
        getVerifyResult({
          userId,
        })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // 刷新认证状态
    refresh() {
      this.$store.dispatch("GetLoginUserInfoByToken");
      this.isRecertification = false;
    },

    recertification() {
      this.isRecertification = true;
    },
  },
};
</script>
<style lang="less" scoped>
.submitBtn {
  text-align: center;
  .el-button {
    padding: 12px 40px;
  }
}
</style>
