<template>
  <div>
    <div v-show="isPersonal">
      <personal></personal>
    </div>
    <div v-show="isEnterprise">
      <enterprise></enterprise>
    </div>
    <div v-show="isSoho">
      <soho></soho>
    </div>
    <div v-show="isPurchase">
      <certification></certification>
    </div>
  </div>
</template>

<script>
import Personal from "./Personal";
import { mapGetters } from "vuex";
import Enterprise from "./Enterprise.vue";
import Soho from "./Soho.vue";
import Certification from "@/views/purchaser/personalCenter/personalManagement/certification/Certification";
export default {
  name: "Authentication",
  components: { Personal, Enterprise, Soho, Certification },
  data() {
    return {
      isPersonal: false,
      isEnterprise: false,
      isSoho: false,
      isPurchase: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    this.getAuthentication();
  },
  methods: {
    getAuthentication() {
      // console.log(this.userInfo.userType)
      if (this.userInfo.userType == "1") {
        this.isPersonal = true;
        this.isEnterprise = false;
        this.isSoho = false;
        this.isPurchase = false;
      } else if (this.userInfo.userType == "2") {
        this.isPersonal = false;
        this.isEnterprise = false;
        this.isSoho = true;
        this.isPurchase = false;
      } else if (this.userInfo.userType == "3") {
        this.isPersonal = false;
        this.isEnterprise = true;
        this.isSoho = false;
        this.isPurchase = false;
      } else if (this.userInfo.roleType == "2") {
        this.isPersonal = false;
        this.isEnterprise = false;
        this.isSoho = false;
        this.isPurchase = true;
      }
    },
  },
};
</script>
