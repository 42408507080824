<template>
  <div class="enterprise">
    <div>
      <certification-content>
        <div slot="menu">
          账号认证
        </div>
        <div slot="title">
          <span class="F16">会员信息</span>
        </div>
        <div slot="information">
          <certification-form></certification-form>
        </div>
      </certification-content>
    </div>
  </div>
</template>
<script>
import CertificationContent from "@/components/certificationContent";
import CertificationForm from "@/components/certificationForm";
export default {
  components: {
    CertificationContent,
    CertificationForm,
  },
  name: "enterprise",
};
</script>
<style scoped lang="scss">
</style>