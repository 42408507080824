<template>
  <div class="soho">
    <certification-content>
      <div slot="menu">账号认证<span class="main-text F16">（自然人）</span></div>
      <div slot="title">
        <span class="F16">会员信息</span>
      </div>
      <div slot="information">
        <personal-form></personal-form>
      </div>
    </certification-content>
  </div>
</template>
<script>
import CertificationContent from "@/components/certificationContent";
import PersonalForm from "@/components/supplier/personalForm.vue";

export default {
  components: {
    CertificationContent,
    PersonalForm,
  },
  name: "enterprise",
};
</script>
<style scoped>
</style>