<template>
  <div class="certificationform">
    <div class="form">
      <el-form
        :model="userInfo"
        :rules="certificationRules"
        ref="certificationForm"
        label-width="100px"
      >
        <el-row :gutter="100">
          <el-col :span="12">
            <el-form-item
              label="公司名称"
              prop="companyName"
              label-width="110px"
            >
              <el-input
                :disabled="disabled"
                v-model="userInfo.companyName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="社会统一信息码"
              prop="unifyNumber"
              label-width="130px"
            >
              <el-input
                :disabled="disabled"
                v-model="userInfo.unifyNumber"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="100">
          <el-col :span="12">
            <el-form-item label="手机号码" prop="mobile" label-width="110px">
              <el-input :disabled="true" v-model.trim="userInfo.mobile"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="地址"
              prop="companyAddress"
              label-width="130px"
            >
              <el-input
                :disabled="disabled"
                v-model="userInfo.companyAddress"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="100">
          <el-col :span="12">
            <el-form-item
              label="银行帐号"
              prop="bankAccount"
              label-width="110px"
            >
              <el-input
                :disabled="disabled"
                v-model="userInfo.bankAccount"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="开户银行"
              prop="openBank"
              label-width="130px"
            >
              <el-input
                :disabled="disabled"
                v-model="userInfo.openBank"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="100">
          <el-col :span="12">
            <el-form-item
              label="法人姓名"
              prop="businessEntity"
              label-width="110px"
            >
              <el-input
                :disabled="disabled"
                v-model="userInfo.businessEntity"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="营业执照"
              prop="businessLicense"
              label-width="130px"
            >
              <el-upload
                :disabled="disabled"
                class="avatar-uploader"
                action="/shop/fileservice/uploadFile"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :headers="token"
                :data="{ moduleType: '05' }"
              >
                <el-image
                  v-if="userInfo.businessLicense"
                  :src="userInfo.businessLicense"
                  style="width: 200px"
                  :preview-src-list="[userInfo.businessLicense]"
                ></el-image>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="Mtp5" style="text-align: center">
          <el-button
            style="font-weight: bold; width: 150px"
            type="primary"
            @click="submitForm('certificationForm')"
            v-if="!disabled"
            >确认提交</el-button
          >
          <el-button
            style="font-weight: bold; width: 150px"
            type="primary"
            @click="disabled = false"
            v-else
            >重新认证</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  updateUser,
  companyAuthentication,
  getBusinessLicenseORC,
} from "@/api/user";
import { getToken } from "@/util/auth";

export default {
  name: "CertificationForm",
  data() {
    let validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else if (!this.checkMobile(value)) {
        callback(new Error("手机号码不合法"));
      } else {
        callback();
      }
    };
    return {
      disabled: false,
      certificationForm: {
        companyName: "",
        unifyNumber: "",
        mobile: "",
        companyAddress: "",
        bankAccount: "",
        openBank: "",
        businessLicense: "",
      },
      certificationRules: {
        companyName: [
          { required: true, message: "公司名称不能为空", trigger: "blur" },
        ],
        businessEntity: [
          { required: true, message: "法人姓名不能为空", trigger: "blur" },
        ],
        unifyNumber: [
          {
            required: true,
            message: "社会统一信用码不能为空",
            trigger: "blur",
          },
        ],
        mobile: [{required: true, validator: validatePhone, trigger: "blur" }],
        companyAddress: [
          { required: true, message: "地址不能为空", trigger: "blur" },
        ],
        bankAccount: [
          { required: true, message: "请输入企业对公银行账户号！", trigger: "blur" },
        ],
        openBank: [
          { required: true, message: "对公账户银行不能为空", trigger: "blur" },
        ],
        businessLicense: [
          { required: true, message: "营业执照不能为空", trigger: "blur" },
        ],
      },
      token: {},
      accessToken: "",
    };
  },
  mounted() {
    // 获取token值
    this.token = {
      "Blade-Auth": "bearer " + getToken(),
    };
    // 根据token获取用户信息并且更新缓存
    this.$store.dispatch("GetLoginUserInfoByToken");
    this.isCertification();
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    //上传营业执照
    handleAvatarSuccess(res) {
      this.userInfo.businessLicense = res.data[0].filePath;
      getBusinessLicenseORC(this.userInfo.businessLicense).then((res) => {
        if (res.success) {
          this.$message.success("信息提取成功");
          let result = res.data;
          for (var key in result.words_result) {
            var obj = result.words_result[key].words;
            if (key == "社会信用代码") {
              this.userInfo.unifyNumber = obj;
            }
            if (key == "地址") {
              this.userInfo.companyAddress = obj;
            }
            if (key == "法人") {
              this.userInfo.businessEntity = obj;
            }
            if (key == "单位名称") {
              this.userInfo.companyName = obj;
            }
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    isCertification() {
      if (this.userInfo.authentication == "Y") {
        this.disabled = true;
      }
    },
    submitForm(certification) {
      this.$refs[certification].validate((valid) => {
        if (valid) {
          updateUser(this.userInfo).then((res) => {
            if (res.success) {
              this.$message.success(res.msg);
              // 实时获取用户信息并且更新缓存
              this.$store.dispatch("GetLoginUserInfoByToken");

              // 提交认证
              companyAuthentication({
                userId: this.userInfo.id,
              }).then((res) => {
                if (res.success) {
                  this.isCertification();
                  this.disabled = true;
                  this.$message.success(res.msg);
                } else {
                  this.$message.error(res.msg);
                }
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          // this.$message.error("error！");
        }
      });
    },
    //手机号码验证
    checkMobile(str) {
      // let reg = /^1\d{10}$/;
      // if (reg.test(str)) {
      //   return true;
      // } else {
      //   return false;
      // }
      if (str.length==11) {
        return true;
      } else {
        return false;
      }
    },
    //银行账户验证
    luhnCheck(bankno) {
      var lastNum = bankno.substr(bankno.length - 1, 1); //取出最后一位（与luhn进行比较）
      var first15Num = bankno.substr(0, bankno.length - 1); //前15或18位
      var newArr = new Array();
      for (var i = first15Num.length - 1; i > -1; i--) {
        //前15或18位倒序存进数组
        newArr.push(first15Num.substr(i, 1));
      }
      var arrJiShu = new Array(); //奇数位*2的积 <9
      var arrJiShu2 = new Array(); //奇数位*2的积 >9
      var arrOuShu = new Array(); //偶数位数组
      for (var j = 0; j < newArr.length; j++) {
        if ((j + 1) % 2 == 1) {
          //奇数位
          if (parseInt(newArr[j]) * 2 < 9)
            arrJiShu.push(parseInt(newArr[j]) * 2);
          else arrJiShu2.push(parseInt(newArr[j]) * 2);
        } //偶数位
        else arrOuShu.push(newArr[j]);
      }

      var jishu_child1 = new Array(); //奇数位*2 >9 的分割之后的数组个位数
      var jishu_child2 = new Array(); //奇数位*2 >9 的分割之后的数组十位数
      for (var h = 0; h < arrJiShu2.length; h++) {
        jishu_child1.push(parseInt(arrJiShu2[h]) % 10);
        jishu_child2.push(parseInt(arrJiShu2[h]) / 10);
      }

      var sumJiShu = 0; //奇数位*2 < 9 的数组之和
      var sumOuShu = 0; //偶数位数组之和
      var sumJiShuChild1 = 0; //奇数位*2 >9 的分割之后的数组个位数之和
      var sumJiShuChild2 = 0; //奇数位*2 >9 的分割之后的数组十位数之和
      var sumTotal = 0;
      for (var m = 0; m < arrJiShu.length; m++) {
        sumJiShu = sumJiShu + parseInt(arrJiShu[m]);
      }

      for (var n = 0; n < arrOuShu.length; n++) {
        sumOuShu = sumOuShu + parseInt(arrOuShu[n]);
      }

      for (var p = 0; p < jishu_child1.length; p++) {
        sumJiShuChild1 = sumJiShuChild1 + parseInt(jishu_child1[p]);
        sumJiShuChild2 = sumJiShuChild2 + parseInt(jishu_child2[p]);
      }
      //计算总和
      sumTotal =
        parseInt(sumJiShu) +
        parseInt(sumOuShu) +
        parseInt(sumJiShuChild1) +
        parseInt(sumJiShuChild2);

      //计算luhn值
      var k = parseInt(sumTotal) % 10 == 0 ? 10 : parseInt(sumTotal) % 10;
      var luhn = 10 - k;

      if (lastNum == luhn) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.el-upload {
  border: 1px solid #f4f4f4;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
