<!--
 * @Author: Jerry
 * @Date: 2021-01-27 09:33:10
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-26 09:32:56
 * @Description: file content
-->
<template>
  <div class="soho">
    <certification-content>
      <div slot="menu">
        账号认证<span class="main-text F16">（个体户）</span>
      </div>
      <div slot="title">
        <span class="F16">法人信息</span>
        <span class="F14 co_f00">（可先上传营业执照，上传成功后会自动读取营业执照内的信息）</span>
      </div>
      <div slot="information">
        <certification-form></certification-form>
      </div>
    </certification-content>
  </div>
</template> 
<script>
import CertificationContent from "@/components/certificationContent";
import CertificationForm from "@/components/certificationForm";
export default {
  components: { CertificationContent, CertificationForm },
  name: "Soho",
};
</script>
<style scoped lang="scss">
</style>